import React from "react";
import { Container, Segment, Input, Button, Form, Modal } from "semantic-ui-react";
import ProductList from "./ProductList";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements
} from 'react-stripe-elements';

// Sample product data (with images)
import tshirt from "./assets/muvor-ss.png";
import long from "./assets/muvor-ls.png";
import hoodie from "./assets/muvor-hoodie.png";
import goku from "./assets/goku.png";
import domain from "./assets/domain.png";
import cold from "./assets/wp-cover.png";

// Product list mockup
const PRODUCT_LIST = {
  items: [
    {
      quantity: 1,
      name: "Muvor T-shirt",
      price: 50,
      currencyId: 1,
      currencyFormat: "$",
      image: { title: "Logo T-shirt", url: tshirt },
      isFreeShipping: true
    },
    {
      quantity: 1,
      name: "Muvor Long-Sleeve",
      price: 55,
      currencyId: 2,
      currencyFormat: "$",
      image: { title: "Logo Sleeved", url: long },
      isFreeShipping: true
    },
    {
      quantity: 1,
      name: "Muvor Hoodie",
      price: 60,
      currencyId: 3,
      currencyFormat: "$",
      image: { title: "Logo Hoodie", url: hoodie },
      isFreeShipping: true
    },
    {
      quantity: 1,
      name: "MVRX Domain",
      price: 5,
      currencyId: 4,
      currencyFormat: "$",
      image: { title: "Domain", url: domain },
      isFreeShipping: true
    },
    {
      quantity: 1,
      name: "MVRX Paper (Cold Storage)",
      price: 5,
      currencyId: 5,
      currencyFormat: "$",
      image: { title: "Cold-Storage Paper", url: cold },
      isFreeShipping: true
    },
    {
      quantity: 1,
      name: "MVRX Engraved",
      price: 40,
      currencyId: 6,
      currencyFormat: "$",
      image: { title: "Cold-Storage Collectable", url: goku },
      isFreeShipping: true
    }
  ]
};

class _SplitFieldsForm extends React.Component {
  constructor(props) {
      super(props);
  
      // Create refs for the card elements
      this.cardNumberRef = React.createRef();
      this.cardExpiryRef = React.createRef();
      this.cardCvcRef = React.createRef();
  }
  state = {
      errorMessage: '',
      cardDetails: {
      complete: false
      },
      totalPrice: 0,
      load: false
  };

  handleChange = (e) => {
      this.setState({
      cardDetails: {
          ...this.state.cardDetails,
          [e.elementType]: e.complete ? e.value : '',
          [e.brand]: e.complete
      },
      });
  }

  handleSubmit = (e) => {
      e.preventDefault();
      const {
          selectedProduct,
          totalPrice,
          setReceiptData,
          setIsPaymentOpen,
          setIsReceiptOpen,
      } = this.props;
      this.setState({ load: true })
      if (this.props.stripe) {
          this.props.stripe.createToken()
          .then(token => { 
              this.props.stripe.createPaymentMethod({
                  type: 'card',
                  card: this.cardNumberRef.current._element,
                  billing_details: {
                      name: this.props.kycInfo.name
                  },
              }).then((result) => {
                  const data = {
                      token,
                      device: selectedProduct,
                      kyc: this.props.formData,
                      card: result.paymentMethod,
                      amount: totalPrice,  // Amount in cents
                      description: `RWA - ${selectedProduct.name}`,
                  };

                  // Fetch POST request
                  fetch('https://api.muvor.com/api/auth/stripe/byn', {
                  // fetch('http://192.168.0.199:9000/api/auth/stripe/byn', {
                      method: 'POST', // Specify the request method
                      headers: {
                          'Content-Type': 'application/json', // Specify the content type as JSON
                      },
                      body: JSON.stringify(data), // Convert the data to JSON and send it in the body
                  }).then(response => {
                      if (!response.ok) {
                          throw new Error('Network response was not ok');
                      };

                      return response.json(); // Parse the JSON from the response
                  }).then(result => {                        
                      this.setState({ setReceiptData: result.data });
                      this.setState({ setIsPaymentOpen: false }); // Close payment modal
                      this.setState({ setIsReceiptOpen: true }); // Open receipt modal
                  }).catch(error => {
                      console.error('Error:', error); // Handle any errors
                  });
              }).catch((err) => {window.alert(`${err}: FAILED. \n\n Click next to continue`);window.location.reload();});
          }).catch(err => window.alert('Token Creation Failed: ', err))
      } else {
          console.log("Stripe.js hasn't loaded yet.");
      }
  };

  handleError = ({error}) => {
      if (error) {
      this.setState({errorMessage: error.message});
      }
  };

  get invalid() {
      const {
      loading
      } = this.props
      const {
      cardDetails,
      load
      } = this.state

      return !(
      !loading
      && cardDetails.undefined
      && !load
      )
  }
  
  render() {
      return (
          <div className="checkoutForm">
              <form>
                  <label>
                      Card details
                      <CardNumberElement ref={this.cardNumberRef} onChange={this.handleChange} />
                  </label>
                  <label>
                      Expiration date
                      <CardExpiryElement ref={this.cardExpiryRef} onChange={this.handleChange} />
                  </label>
                  <label>
                      CVC
                      <CardCVCElement ref={this.cardCvcRef} onChange={this.handleChange} />
                  </label>
              </form>
              <div className="split">
                  <button className="b1" onClick={this.props.onCancel}>Cancel</button>
                  <button className="b2" onClick={this.handleSubmit}>
                      Pay ${this.props.totalPrice.toFixed(2)}
                  </button>
              </div>
          </div>
      );
  }
}

const SplitFieldsForm = injectStripe(_SplitFieldsForm);

class Products extends React.Component {
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [deviceComplete, setDeviceComplete] = useState({});
  // const [selectedDeviceType, setSelectedDeviceType] = useState('');
  // const [selectedCPU, setSelectedCPU] = useState('');
  // const [selectedGPU, setSelectedGPU] = useState('');
  // const [selectedRAM, setSelectedRAM] = useState('');
  // const [selectedROM, setSelectedROM] = useState('');
  // const [selectedNIC, setSelectedNIC] = useState('');
  // const [selectedBrand, setSelectedBrand] = useState('');
  // const [selectedOS, setSelectedOS] = useState(''); // OS Selection state
  // const [selectedPeripherals, setSelectedPeripherals] = useState([]);
  // const [selectedAccessories, setSelectedAccessories] = useState([]);
  // const [selectedDualCPUs, setSelectedDualCPUs] = useState([]);
  // const [selectedDualGPUs, setSelectedDualGPUs] = useState([]);
  // const [isRefurbished, setIsRefurbished] = useState(false);
  // const [openAccordion, setOpenAccordion] = useState(null);
  // const [isPaymentOpen, setIsPaymentOpen] = useState(false); // Modal state for payment
  // const [isReceiptOpen, setIsReceiptOpen] = useState(false); // Modal state for receipt
  // const [receiptData, setReceiptData] = useState(null); // Store receipt data
  // const [isKycOpen, setIsKycOpen] = useState(false); // Modal state for KYC
  state = {
    loading: false,
    showKYC: false, // Control KYC modal visibility
    showCardInput: false, // Control Card modal visibility
    selectedProduct: null, // The product being checked out
    receiptData: null,
    showReceipt: false,
    formData: { name: "", email: "", address: "" }, // KYC form data
    cardData: { cardNumber: "", expiryDate: "", cvv: "" }, // Card form data
    kycInfo: { name: '', email: '', address: '' },
    
  };

  // Handles search input changes (mockup for now)
  handleSearch = (e) => {
    const searchTerm = e.target.value;
    console.log("Search for:", searchTerm);
    // Add search logic or API integration here
  };

  // Trigger KYC modal when user clicks checkout
  handleCheckout = (product) => {
    this.setState({
      selectedProduct: product,
      showKYC: true // Show KYC modal
    });
  };

  handleClose = () => {
    this.setState({
      showKYC: false // Show KYC modal
    });
  };

  handleCancel = () => {
    this.setState({
      showCardInput: false // Show KYC modal
    });
  };

  // Handle KYC form submission and show Card Input modal
  handleKYCSubmit = (e) => {
    e.preventDefault();
    console.log("KYC Data Submitted", this.state.formData);
    this.setState({ showCardInput: true, showKYC: false }); // Show Card Input modal
  };

  // Handle Card Input form submission
  handleCardSubmit = (e) => {
    e.preventDefault();
    console.log("Card Payment Submitted", this.state.cardData);
    alert(`Payment for ${this.state.selectedProduct.name} was successful!`);
    this.setState({ showCardInput: false, selectedProduct: null }); // Reset after payment
  };

  // Handle input changes for forms
  handleInputChange = (e, formType) => {
    const { name, value } = e.target;
    if (formType === "kyc") {
      this.setState((prevState) => ({
        formData: { ...prevState.formData, [name]: value }
      }));
    } else if (formType === "card") {
      this.setState((prevState) => ({
        cardData: { ...prevState.cardData, [name]: value }
      }));
    }
  };

  render() {
    const { loading, showKYC, showCardInput, selectedProduct, formData, cardData, receiptData, showReceipt } = this.state;

    return (
      <Container>
        <Segment loading={loading}>
          {/* Search Bar */}
          <Input
            name="search"
            onChange={this.handleSearch}
            fluid
            icon="search"
            placeholder="Search for products..."
            style={{ marginBottom: "50px", marginTop: "30px" }}
          />

          {/* Product List */}
          <ProductList products={PRODUCT_LIST.items} onCheckout={this.handleCheckout} />

          {showKYC && (
          <div className="modal">
              <div className="modal-content">
              <h2>Enter Your Information</h2>
              <form>
                  <label>
                  Name:
                  <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={this.handleInputChange}
                  />
                  </label>
                  <label>
                  Email:
                  <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={this.handleInputChange}
                  />
                  </label>
                  <label>
                  Address:
                  <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={this.handleInputChange}
                  />
                  </label>
              </form>
              <div className="split">
                  <button className="b1" onClick={this.handleClose}>Cancel</button>
                  <button className="b2" onClick={this.handleKYCSubmit}>
                    Proceed to Payment
                  </button>
              </div>
              </div>
          </div>
          )}

        {showCardInput && (
          <div className="modal">
            <div className="modal-content">
              <h2>Enter Payment Details</h2>
              {
                  // <StripeProvider apiKey="pk_test_zg1kvZwcfPwJc35UXpGr6xMy00Rpleq9Ll">
              }
              <StripeProvider apiKey="pk_live_HVSDLMk8WKyXJpbT1GTTKefb00YvpebVqb"> 
                  <Elements>
                      <SplitFieldsForm 
                          totalPrice={selectedProduct.price} 
                          setReceiptData={receiptData}
                          setIsPaymentOpen={showCardInput}
                          setIsReceiptOpen={showReceipt}
                          onCancel={this.handleCancel}
                          kycInfo={formData}
                      />
                  </Elements>
              </StripeProvider>
            </div>
          </div>
        )}

        {this.state.showReceipt && (
          <div className="modal">
            <div className="modal-content">
              <h2>Receipt</h2>
              <p><strong>Description:</strong> {receiptData.description}</p>
              <p><strong>Amount:</strong> ${receiptData.amount.toFixed(2)}</p>
              <p><strong>Transaction ID:</strong> {receiptData.identifier}</p>
              <button onClick={() => this.setState({ showReceipt: false })}>Close</button>
            </div>
          </div>
        )}
        </Segment>
      </Container>
    );
  }
}

export default Products;
