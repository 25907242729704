import React from "react";
import Product from "./Product";
import SelfHeader from "./SelfHeader"; // Assuming SelfHeader displays some summary like product count
import "./style.css";

const ProductList = ({ products, onCheckout }) => {
  return (
    <div>
      <SelfHeader productsLength={products.length} />
      <div className="list_items">
        {products.map((product) => (
          <Product key={product.name} product={product} onCheckout={onCheckout} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
