// src/App.js
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import React from 'react';
import Home from './Home';
import Products from './Products';
import AddProduct from './add/AddAProduct';
import KYC from './KYC';
import BYN from './BYN';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <header className="app-header">
          <nav>
            <Link to="/main" className="nav-link">
              RWA
            </Link>
            <span className="nav-link disabled-link" title="Coming Soon!">
              Hot Wallets
            </span>
            <Link to="/build-device" className="nav-link">
              Cold Wallets
            </Link>
          </nav>
        </header>
        <main>
          <Switch>
            <Route path="/kyc-form" component={KYC} />
            <Route path="/build-device" component={BYN} />
            <Route path="/main" exact component={Home} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/product/add" component={AddProduct}/>
            <Route path="/" exact component={BYN} /> {/* Default Route */}
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;
