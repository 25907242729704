import React from "react";
import Thumb from "../../share/Thumb";
import { formatPrice } from "../../share/utils";

const Product = ({ product, onCheckout }) => {
  product.quantity = 1;

  let formattedPrice = formatPrice(product.price, product.currencyId);

  let productInstallment;
  if (product.installments) {
    const installmentPrice = product.price / product.installments;
    productInstallment = (
      <div className="installment">
        <span>or {product.installments} x </span>
        <b>{product.currencyFormat}{formatPrice(installmentPrice, product.currencyId)}</b>
      </div>
    );
  }

  return (
    <div className="shelf-item" data-sku={product.sku}>
      {product.isFreeShipping && <div className="shelf-stopper">Free shipping</div>}
      <Thumb classes="shelf-item__thumb" src={product.image.url} alt={product.image.title} />
      <div className="item_infor">
        <p className="shelf-item__title">{product.name}</p>
        <p>
          <span>Price: </span>
          <small>{product.currencyFormat}</small>
          <b>{formattedPrice}</b>
        </p>
        {productInstallment}
        <p>Total Products: {product.quantity}</p>
      </div>
      <button className="shelf-item__buy-btn" onClick={() => onCheckout(product)}>
        CHECKOUT
      </button>
    </div>
  );
};

export default Product;
