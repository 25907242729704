import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import './Home.css'; // Custom CSS for styling

class HomepageLayout extends Component {
  render() {
    return (
      <div className="branding-container">
        {/* Branding section */}
        <div className="branding">
          <h1 className="branding-header">Muvor Real World Assets</h1>
          <p className="branding-text">Create, buy, and trade assets.</p>

          {/* Navigation buttons */}
          <div className="navigation-buttons">
            <Link to="/products">
              <button className="nav-button">Explore Products</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default HomepageLayout;
